<template>
    <Component :is="as">
        {{ date ? $d(dayjs(date).toDate(), format) : '-' }}
    </Component>
</template>

<script setup>
defineProps({
    as: {
        type: String,
        default: "span"
    },
    date: {
        type: String,
        required: true,
        default: null
    },
    format: {
        type: String,
        default: "dateShort"
    }
})

const dayjs = useDayjs()

</script>

<style scoped>

</style>